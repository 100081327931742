<div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-stretch auth auth-img-bg">
            <div class="row flex-grow main-panel">
                <div class="content-wrapper d-flex  align-items-center justify-content-center auth px-0">
                    <div class="row w-100 mx-0">
                        <div class="col-lg-4 mx-auto">
                    <div class="auth-form-transparent  text-left py-5 px-4 px-sm-5 border">
                        <div class="brand-logo">
                            <img src="assets/images/IRISGST-logo.png" alt="logo">
                        </div>
                       
                        <form [formGroup]="completeRegForm" class="pt-3" autocomplete="off">
                            <h3 class="clrWhite">Complete Registration</h3>
                            <div> Welcome {{username}} </div>
                            <div class="form-group">
                                    <input type="password" class="form-control"
                                        formControlName="password" placeholder="Set Password">
                                    <div *ngIf="isSubmitted">
                                        <span class="text-danger"
                                            *ngIf="!completeRegForm.get('password').dirty && completeRegForm.get('password').errors.required">Password
                                            Required
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="completeRegForm.get('password').dirty && completeRegForm.get('password').errors">Invalid
                                            Password
                                        </span>
                                    </div>
                            </div>
                            <div class="form-group">
                                <input type="number" class="form-control" formControlName="phone"
                                    placeholder="Mobile number">
                                <div *ngIf="isSubmitted">
                                    <span class="text-danger"
                                        *ngIf="!completeRegForm.get('phone').dirty && completeRegForm.get('phone').errors.required">Mobile
                                        Number
                                        Required
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="completeRegForm.get('phone').dirty && completeRegForm.get('phone').errors">Invalid
                                        Mobile Number
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="number" class="form-control" formControlName="adhaar"
                                    placeholder="Adhaar number">
                                <div *ngIf="isSubmitted">
                                    <!-- <span class="text-danger"
                                        *ngIf="!completeRegForm.get('adhaar').dirty && completeRegForm.get('adhaar').errors.required">Adhaar
                                        Number
                                        Required
                                    </span> -->
                                    <span class="text-danger"
                                        *ngIf="completeRegForm.get('adhaar').dirty && completeRegForm.get('adhaar').errors">Invalid
                                        Adhaar Number
                                    </span>
                                </div>
                            </div>
                            <div class="mb-2 d-flex">
                                <button type="button" class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                                    (click)="completeRegistration()">
                                    Complete Registration
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
                </div>
                
            </div>
        </div>
    </div>
</div>
