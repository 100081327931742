import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BusinessEntityService {


  businessEntityData = new BehaviorSubject<any>({ entityName: '', isTaxPayer: false });


  constructor(
    private http: HttpClient
  ) { }

  fetchEntity() {
    return this.http.get(`${environment.apiUrl}/mgmt/user/entity`).pipe(map((response) => {
      return response;
    }));
  }
  getUserProfileData() {
    return this.http.get(`${environment.apiUrl}/mgmt/user/profile`).pipe(map((response) => {
      return response;
    }));
  }

  fetchFillingBusiness() {
    return this.http.get(`${environment.apiUrl}/mgmt/user/company/filingbusiness`).pipe(map((response) => {
      return response;
    }));
  }

  fetchBusinessHierarchy(companyId) {
    return this.http.get(`${environment.apiUrl}/mgmt/company/businesshierarchy?companyid=${companyId}`).pipe(map((response) => {
      return response;
    }));
  }

  changeCompany(companyId) {
    return this.http.post(`${environment.apiUrl}/mgmt/user/changecompany?companyid=${companyId}`, {}).pipe(map((response) => {
      return response;
    }));
  }
  updateUserProfileData(data) {
    return this.http.post(`${environment.apiUrl}/mgmt/user/profile`, data).pipe(map((response) => {
      return response;
    }));
  }

  validateGstnSessionUrl(gstn) {
    return this.http.get(`${environment.apiUrl}/mgmt/company/nicCredentialsStatus?gstin=${gstn}`).pipe(map((response) => {
      return response;
    }));
  }

  // setBusinessData(data) {
  //   this.businessEntityData.next(data);
  // }
  // getPageData() {
  //   return this.businessEntityData.asObservable();
  // }

  getSetBusinessData(data?) {
    if (data) {
      this.businessEntityData.next(data);
    } else {
      return this.businessEntityData.asObservable();
    }
  }

  fetchQRData(){
    return this.http.put(`${environment.apiUrl}/mgmt/mfa/register`,{}).pipe(map((resp) => {
      return resp;
    }));
  }

  verifyOtp(data){
    return this.http.get(`${environment.apiUrl}/mgmt/mfa/verifytotp?code=${data.code}&email=${data.email}`).pipe(map((resp) => {
      return resp;
    }));
  }

  fetchMFADetails(){
    return this.http.get(`${environment.apiUrl}/mgmt/mfa/details`).pipe(map((resp) => {
      return resp;
    }));
  }

  optout(){
    return this.http.put(`${environment.apiUrl}/mgmt/mfa/optoutMfa`,{}).pipe(map((resp) => {
      return resp;
    }));
  }

  fetchAuditLogs(page:any, size:any){
    return this.http.get(`${environment.apiUrl}/mgmt/audit/logs?page=${page}&size=${size}`).pipe(map((resp) => {
      return resp;
    }));
  }
}
