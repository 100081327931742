import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TokenService } from '../services/token.service';
import { GetterSetterService } from '../services/getter-setter.service';
import { LoaderService } from '../services/loader.service';
import { ToasterService } from '../services/toaster.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private tokenService: TokenService,
    private GetSet: GetterSetterService,
    private loaderService: LoaderService,
    private toaster: ToasterService,
    private authService: AuthService,
    private router: Router
  ) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.showLoader();
    const { url } = req;

    if (url.indexOf("/login/sso") > -1) {
      req = req.clone({
        headers: req.headers.set("Content-Type", "application/json"),
      });

      return next.handle(req).pipe(
        tap(
          (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this.onEnd();
            }
          },
          (err: any) => {
            this.onEnd();
          }
        )
      );
    }

    if (url.indexOf("/public/token/") > -1) {

      req = req.clone({
        headers: req.headers.set("tenant", "asp"),
      });

      return next.handle(req).pipe(
        tap(
          (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this.onEnd();
            }
          },
          (err: any) => {
            this.onEnd();
          }
        )
      );
    }

    if (url.indexOf('/login') > -1 || url.indexOf('/registration') > -1 || url.indexOf('/checktokenvalidity') > -1 || url.indexOf('/verifytotp') > -1 ||
      url.indexOf('/forgotpassword') > -1 || url.indexOf('/completeRegistration') > -1 || url.indexOf('/resetpassword') > -1 || url.indexOf('/confirmregistration') > -1
      || url.indexOf('/user/changepassword') > -1 || (url.indexOf('/subscription') > -1 && url.indexOf('/active') < 0)) {

      // req = req.clone({
      //     headers: req.headers.set('tenant', 'dev')
      // });
      return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.onEnd();
        }
      },
        (err: HttpErrorResponse) => {
          this.handleError(err);
        }));
    }

    req = req.clone({
      headers: req.headers.set('x-auth-token', this.tokenService.getter())
    });

    req = req.clone({
      setHeaders: {
        // "Permissions-Policy": "camera=*,geolocation=*,microphone=*,autoplay=*,fullscreen=*,picture-in-picture=*,sync-xhr=*,encrypted-media=*,oversized-images=*",
        "Strict-Transport-Security": "max-age=31536000; includeSubdomains",
        "X-Frame-Options": "SAMEORIGIN",
        "X-Content-Type-Options": "nosniff",
        "X-Xss-Protection": "1; mode=block",
        "Content-Security-Policy": "script-src https: 'unsafe-inline' 'unsafe-eval';style-src https: 'unsafe-inline' 'unsafe-eval';img-src https: data:;font-src https: data:;"
      }
    });

    if (this.GetSet.getSetCompanyId()) {
      req = req.clone({
        headers: req.headers.set('companyId', this.GetSet.getSetCompanyId())
      });
    }

    if (url.indexOf('/upload/invoices') > -1 || url.indexOf('/einvoice/logo/add') > -1) {
      // req = req.clone({
      //     headers: req.headers.set('Content-Type', 'multipart/form-data')
      // });
    } else {
      req = req.clone({
        headers: req.headers.set('Content-Type', 'application/json')
      });
    }
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this.onEnd();
      }
    },
      (err: HttpErrorResponse) => {
        this.handleError(err);
      }));
  }

  private handleError(err: HttpErrorResponse) {
    switch (err.status) {
      case 400:
      case 401:
      case 403:
        let errMsg = err?.error?.message == "Token mismatch" ?
          "Token mismatch. Your session has been terminated due to a new login" : err?.error?.message;
        if(errMsg == "Token mismatch. Your session has been terminated due to a new login"){
          if (this.tokenService.destroy()) {
            this.authService.setLoginIndicator(0);
            this.router.navigate(['login']);
          }
        }
        this.toaster.showError(errMsg);
        break;
      case 512:
        break;
      case 513:
        break;
      case 514:
        break;
      default:
    }
    this.onEnd();
  }

  private onEnd(): void {
    this.hideLoader();
  }
  private showLoader(): void {
    this.loaderService.show();
  }
  private hideLoader(): void {
    this.loaderService.hide();
  }
}
