import { Injectable } from '@angular/core';
import { UserRoleMap } from '../constant';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetterSetterService {


  userRole = UserRoleMap;
  // checkEntityType = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('navContext')));
  checkEntityType = new BehaviorSubject<any>(null);

  constructor() { }

  setGet_UserRole(roleId?: number) {
    if (roleId) {
      let role = this.userRole[roleId];
      localStorage.setItem('role', role.role);
    } else {
      return localStorage.getItem('role');
    }
  }

  getSetCompany(company?) {
    if (company) {
      localStorage.setItem('company', company);
    } else {
      company = localStorage.getItem('company');
    }
    return company;
  }

  getSetProductAccess(prodcutName, access) {
    if (access) {
      localStorage.setItem(prodcutName, access);
    } else {
      prodcutName = localStorage.getItem(prodcutName);
    }
    return prodcutName;
  }

  setProductAccess(prodcutName, access) {
    localStorage.setItem(prodcutName, access);
    return prodcutName;
  }

  setProduct(product) {
    return localStorage.setItem('products', product);
  }


  getSetCompanyId(companyid?) {
    if (companyid) {
      localStorage.setItem('companyId', companyid);
    } else {
      companyid = localStorage.getItem('companyId');
    }
    return companyid;
  }

  setMFACode(code) {
    localStorage.setItem('mfaCode', code);
    return;
  }

  getMFACode() {
    return localStorage.getItem('mfaCode');
  }

  getSetUser(user) {
    if (user) {
      localStorage.setItem('user', user);
    } else {
      user = localStorage.getItem('user');
    }
    return user;
  }
  getSetSapphireTenent(user) {
    if (user) {
      localStorage.setItem('sapphireTenent', user);
    } else {
      user = localStorage.getItem('sapphireTenent');
    }
    return user;
  }

  getSetUserId(UserId?) {
    if (UserId) {
      localStorage.setItem('UserId', UserId);
    } else {
      UserId = localStorage.getItem('UserId');
    }
    return UserId;
  }

  getNavContextData() {
    return localStorage.getItem('navContext');
  }

  getSetFilingGstin(gstinData: any) {
    if (gstinData) {
      localStorage.setItem('filingGstn', gstinData);
    } else {
      gstinData = localStorage.getItem('filingGstn');
    }
    return gstinData;
  }

  getSetfilingEntityName(data?: any) {
    if (data) {
      localStorage.setItem('filingEntityName', data);
    } else {
      data = localStorage.getItem('filingEntityName');
    }
    return data;
  }

  setViewHierarchyData(data: any) {
    localStorage.setItem('viewHierarchy_id', data.companyId);
    localStorage.setItem('viewHierarchy_entityType', data.entityType);
    localStorage.setItem('viewHierarchy_name', data.companyName);
  }
  getviewHierarchy_id() {
    return localStorage.getItem('viewHierarchy_id');
  }
  getviewHierarchy_name() {
    return localStorage.getItem('viewHierarchy_name');
  }
  setBusinessData(data) {
    this.checkEntityType.next(data);
  }
  getBusinessData() {
    return this.checkEntityType.asObservable();
  }
  getTopazAccess() {
    return localStorage.getItem('Topaz');
  }
}
