import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import {
  LoginComponent,
  SignUpComponent,
  ResetPasswordComponent,
  ForgotPasswordComponent
} from './auth'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChangePasswordComponent } from './auth/components/change-password/change-password.component';
import { CompleteRegistrationComponent } from './auth/components/complete-registration/complete-registration.component';
import { ThankYouComponent } from './auth/components/thank-you/thank-you.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { BusinessHeaderComponent } from './base/components/business-header/business-header.component';
import { FooterComponent } from './base/components/footer/footer.component';
import { NavBarComponent } from './base/components/nav-bar/nav-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TokenService } from './shared/services/token.service';
import { ToasterService } from './shared/services/toaster.service';
import { AuthService } from './auth/services/auth.service';
import { LoaderComponent } from './base/components/loader/loader.component';
import { ApiInterceptor } from './shared/interceptor/api.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { AddBusinessComponent } from './add-business/add-business.component';
import { SessionClearComponent } from './auth/components/session-clear/session-clear.component';
import { UpdatePasswordComponent } from './auth/components/update-password/update-password.component';
import { SubscriptionComponent } from './base/components/subscription/subscription.component';
import { VerifyOtpComponent } from './auth/components/verify-otp/verify-otp.component';
import { DomainSelectionComponent } from './domain-selection/domain-selection.component';
// import {CUSTOM_ELEMENTS_SCHEMA} from "@angular/core";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaFormsModule, RecaptchaModule, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { PaginatorModule } from 'primeng/paginator';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    CompleteRegistrationComponent,
    ResetPasswordComponent,
    ThankYouComponent,
    DashboardComponent,
    BusinessHeaderComponent,
    FooterComponent,
    NavBarComponent,
    LoaderComponent,
    AddBusinessComponent,
    SessionClearComponent,
    UpdatePasswordComponent,
    SubscriptionComponent,
    VerifyOtpComponent,
    DomainSelectionComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    RecaptchaModule,
    RecaptchaV3Module,
    RecaptchaFormsModule,
    PaginatorModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-top-right",
      preventDuplicates: true,
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.siteKey },
    TokenService,
    AuthService,
    ToasterService,
  ],
  bootstrap: [AppComponent],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
