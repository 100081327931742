<div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-stretch auth auth-img-bg">
            <div class="row flex-grow main-panel">
                <div class="content-wrapper d-flex  align-items-center justify-content-center auth px-0">
                    <div class="row w-100 mx-0">
                        <div class="col-lg-4 mx-auto">
                            <div class="auth-form-transparent  text-left py-5 px-4 px-sm-5 border">
                                <div class="brand-logo">
                                    <img src="assets/images/IRISGST-logo.png" alt="logo">
                                </div>
                              
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="loginRight align-items-center justify-content-between">
                                            <h1 class="txtWhite">Thank you</h1>
                                            <p class="txtWhite2">Thank you for signing up for IRIS GST. Please complete the registration process by clicking on the link in the invitation mail sent to your email ID. </p>
            
                                        </div>
                                       
                                       
                                    </div>
                                </div>
                                <!-- <div class="row" id="loginRightSec">
                                    <div class="col-md-12">
                                        <div class="loginRight align-items-center justify-content-between">
                                            <h1 class="txtWhite">IRIS UUM</h1>
                                            <h6 class="txtWhite">One-stop-solution for all e-invoicing needs.</h6>
            
                                        </div>
                                    </div>
                                    <div class="col-md-12" id="loginLinking">
            
                                        <p class="text-white font-weight-medium text-center flex-grow align-self-end">Know all about
                                            e-invoicing </p>
                                        <p class="text-white font-weight-medium text-center flex-grow align-self-end">Read our blogs
                                            <a href="https://www.irisgst.com/category/blog/e-invoicing/" target="_nrew">here</a> </p>
            
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- <div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-stretch auth auth-img-bg">
            <div class="row flex-grow">
                <div class="col-lg-6 d-flex align-items-center justify-content-center">
                    <div class="auth-form-transparent text-left p-3">
                        <div class="brand-logo">
                            <img src="assets/images/iris-logo.png" alt="logo">
                        </div>
                    </div>
                    <div class="clearfix"></div>
						<hr class="colorgraph">						
						<div class="panel-body">
							<div class="row">
								<div class="col-lg-12">
									<p class="thanktxt">	
										Thank you for signing up for IRIS GST. Please complete the registration process by clicking on the link in the invitation mail sent to your email ID. 
									</p>
								</div>
							</div>
						</div>
                </div>
                <div class="col-lg-6 login-half-bg align-items-center justify-content-center">
                    <div class="row" id="loginRightSec">
                        <div class="col-md-12">
                            <div class="loginRight align-items-center justify-content-between">
                                <h1 class="txtWhite">IRIS UUM</h1>
                                <h6 class="txtWhite">One-stop-solution for all e-invoicing needs.</h6>

                            </div>
                        </div>
                        <div class="col-md-12" id="loginLinking">

                            <p class="text-white font-weight-medium text-center flex-grow align-self-end">Know all about
                                e-invoicing </p>
                            <p class="text-white font-weight-medium text-center flex-grow align-self-end">Read our blogs
                                <a href="https://www.irisgst.com/category/blog/e-invoicing/" target="_nrew">here</a> </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
