import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToasterService } from '../../../shared/services/toaster.service';
import { BaseResponse } from '../../../models/response';

@Component({
  selector: 'app-complete-registration',
  templateUrl: './complete-registration.component.html',
  styleUrls: ['./complete-registration.component.scss']
})
export class CompleteRegistrationComponent implements OnInit {

  completeRegForm: FormGroup;
  isSubmitted: boolean = false;
  tokenParam: any;
  username: any;
  tokenVerify: boolean;
  constructor(
    private fBuild: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private toaster: ToasterService,
    private activeSnapShot: ActivatedRoute
  ) {
    this.tokenParam = this.activeSnapShot.snapshot.queryParams.token;
    this.username = this.activeSnapShot.snapshot.queryParams.userFirstName;
  }

  ngOnInit() {
    this.formInitialization();
    if (this.tokenParam != undefined && this.tokenParam != null) {
      /*checking token is valid or not*/
      this.authService.tokenVerify(this.tokenParam).subscribe((response: BaseResponse) => {
        if (response.status == 'SUCCESS') {
          this.tokenVerify = true;
          this.username = response.response.username;
        } else {
          this.tokenVerify = false;
          this.toaster.showError("Your token has been expired");
          this.router.navigate(['login']);
        }
      });
    } else {
      this.toaster.showWarning('Your token has been expired');
      this.router.navigate(['login']);
    }
  }


  formInitialization() {
    this.completeRegForm = this.fBuild.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16), Validators.pattern(new RegExp('(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=.\\[\\]*?])(?=\\S+$).{8,16}'))]],
      phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"), Validators.minLength(10), Validators.maxLength(10)]],
      adhaar: ['', [Validators.minLength(12), Validators.maxLength(12), Validators.pattern("^((\\+91-?)|0)?[0-9]{12}$")]]
    });
  }

  completeRegistration() {
    this.isSubmitted = true;
    if (this.completeRegForm.valid && this.tokenParam) {
      this.completeRegForm.value.token = this.tokenParam;
      this.authService.confirmRegistration(this.completeRegForm.value).subscribe((response: BaseResponse) => {
        if (response.status == 'SUCCESS') {
          this.toaster.showSuccess(response.message);
          this.router.navigate(['login']);
        } else {
          this.toaster.showError(response.message);
        }
      });
    }
  }

}
