import { Component, OnInit } from "@angular/core";
import { DOMAIN_LIST, DomainList } from "../shared/constant";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../auth/services/auth.service";
import { BaseResponse } from "../models/response";
import { ToasterService } from "../shared/services/toaster.service";

@Component({
  selector: "app-domain-selection",
  templateUrl: "./domain-selection.component.html",
  styleUrls: ["./domain-selection.component.scss"],
})
export class DomainSelectionComponent implements OnInit {
  domainList: DomainList[] = [];
  domainForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    this.domainForm = this.formBuilder.group({
      domainName: ["", Validators.required],
    });
    this.fetchDomainList()
  }

  fetchDomainList() {
    this.authService.fetchDomainList().subscribe((resp: any) => {
      console.log(resp);
      this.domainList = resp.response.map((domain, index) => {
        return {
          id: index + 1,
          key: domain.Domain,
          value: domain.Domain
        }
      })
    })
  }

  // Access formcontrols getter
  get domainName() {
    return this.domainForm.get("domainName");
  }

  changeDomain(event: any): void {
    this.domainName?.setValue(event.target.value, {
      onlySelf: true,
    });
  }

  signInSAML() {
    try {
      this.authService
        .getSAMLLoginLink(this.domainForm.value.domainName)
        .subscribe((response: BaseResponse) => {
          console.log("Response: ", response);
          if (response.status === "SUCCESS")
            window.location.replace(response.response);
          else this.toasterService.showError(response.message);
        });
    } catch (error) {
      this.toasterService.showError(
        "There is an error while collecting data. Please refresh the page."
      );
    }
  }
}
