import { Component } from '@angular/core';
import { AuthService } from './auth/services/auth.service';
import { Router } from '@angular/router';
import { ToasterService } from './shared/services/toaster.service';
import { BaseResponse } from './models/response';
import { TokenService } from './shared/services/token.service';
import { GetterSetterService } from './shared/services/getter-setter.service';
import { LoaderService } from './shared/services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'uum';
  showHead = false;
  currentURLToken: string = "";

  constructor(
    private auth: AuthService,
    private router: Router,
    private toaster: ToasterService,
    private tokenService: TokenService,
    private getSet: GetterSetterService,
    private loaderService: LoaderService
  ) {
    // this.showheader();

    const url = window.location;
    if (url.search !== "") {
      this.loaderService.show();
      this.currentURLToken = new URLSearchParams(url.search).get("token");

      this.auth
        .validateToken(this.currentURLToken)
        .subscribe((tokenResp: BaseResponse) => {
          if (tokenResp.status === "SUCCESS") {
            this.toaster.showSuccess("Login Successful.");
            this.tokenService.setter(tokenResp.response);
            this.auth.setLoginIndicator(2);
            this.getSet.getSetUser(tokenResp.response.username);
            this.getSet.getSetSapphireTenent("asp");
            this.getSet.getSetUserId(tokenResp.response.email);
            if (tokenResp.response.rootCompanyid != undefined) {
              this.getSet.getSetCompanyId(tokenResp.response.rootCompanyid);
              this.getSet.getSetCompany(tokenResp.response.rootCompanyName);
              if (tokenResp.response.products.length > 0) {
                this.getSet.setProduct(tokenResp.response.products);
              }
              this.router.navigate([`dashboard`]);
            } else {
              this.router.navigate([`add-business`]);
            }
          } else {
            this.router.navigate(["login"]);
          }
          this.loaderService.hide();
        });
    }

    this.auth.getLoginIndicator().subscribe((value) => {
      if (value === 0) {
        this.showHead = false;
      } else if (value === 1) {
        this.showHead = false;
      } else if (value === 2) {
        this.showHead = true;
      }
    });
  }
}
