import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { timer } from 'rxjs';
@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {

  constructor(
    private router: Router,
  ) {
  }

  ngOnInit() {
    timer(5000).subscribe((val) => {
      this.router.navigate(['login']);
    });
  }

}
