import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { BaseResponse } from '../../../models/response';
import { ToasterService } from '../../../shared/services/toaster.service';
import { environment } from 'src/environments/environment';
import { RecaptchaComponent } from 'ng-recaptcha';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  isSubmitted: boolean = false;
  captchaString: string = '';
  captchaResponse: string = '';
  count: number = 0;
  recaptchaSiteKey: string = environment.recaptcha.siteKey;
  @ViewChild('invisible') invisibleCaptcha: RecaptchaComponent;

  constructor(
    private _fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private toaster: ToasterService
  ) { }
  passwordForm: FormGroup;
  ngOnInit() {
    this.initialize();
  }

  initialize() {
    this.passwordForm = this._fb.group({
      email: ['', [Validators.required, Validators.email]],
      // captcha: ['', [Validators.required]]
    });

    this.generateCaptcha();
  }

  resetPassword() {
    this.isSubmitted = true;
    if (this.passwordForm.valid) {
      this.invisibleCaptcha.execute();
    }
  }

  proceedForPasswordReset() {
    this.authService
      .resetPassword(this.passwordForm.value)
      .subscribe((response: BaseResponse) => {
        if (response.status == "SUCCESS") {
          this.toaster.showSuccess(response.message);
          this.router.navigate(["login"]);
        } else {
          this.toaster.showError(response.message);
        }
        this.invisibleCaptcha.reset();
      });
  }


  signIn() {
    this.router.navigate(['login']);
  }

  generateCaptcha(): void {
    this.captchaString = this.authService.generateCaptcha(6);
  }

  resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
    if (captchaResponse) {
      this.proceedForPasswordReset(); // Call data reset function directly after captcha resolved
    }
  }

}
