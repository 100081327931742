import { Component, OnInit } from '@angular/core';
import { TokenService } from 'src/app/shared/services/token.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Router } from '@angular/router';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { BaseResponse } from 'src/app/models/response';

import { BusinessEntityService } from 'src/app/shared/services/business-entity.service';
import { productAccessName } from '../../../shared/constant';

@Component({
  selector: 'app-business-header',
  templateUrl: './business-header.component.html',
  styleUrls: ['./business-header.component.scss']
})
export class BusinessHeaderComponent implements OnInit {



  BNBusinessList: any = [];
  subArrowShow: boolean;
  BNSelectedComs: any;
  NavEntityContext: any;
  isRole: any;
  companyId: any;
  BNModel: any;
  businessName: any;
  familyData: any;
  business_role: any;
  count: number;
  gstin_count: number;
  companyList: any;
  gstnMsg: boolean;
  currentUser: String;
  selectedcom: any;
  userRole: any;
  rootBusinessName: string;
  productAccessName: { onyx: string; topaz: string; sapphire: string; };


  constructor(
    private tokenService: TokenService,
    private authService: AuthService,
    private router: Router,
    private toaster: ToasterService,
    private entityService: BusinessEntityService,
    private GetSet: GetterSetterService,
    private getterSetter: GetterSetterService,
  ) {
    this.productAccessName = productAccessName;
  }

  ngOnInit() {
    this.initializeData();
  }

  initializeData() {
    this.currentUser = localStorage.getItem('user');
    this.rootBusinessName = localStorage.getItem('company');
    const authToken = localStorage.getItem('logintoken') ? localStorage.getItem('logintoken') : null;
    if (authToken) {
      this.getBusinessHierarchy();
    }
    // this.changeBusiness(localStorage.getItem('companyId'));
  }
  navigateDashboard() {
    this.router.navigate([`dashboard`]);
  }

  logout() {
    if (this.tokenService.destroy()) {
      this.authService.setLoginIndicator(0);
      this.router.navigate(['login']);
    } else {
      this.toaster.showError('Something Went wrong !');
    }
  }
  goToAddBusissness() {
    this.router.navigate([`add-business`]);
  }
  goToChangePassword(type) {
    if (type == 'changePass')
      this.router.navigate([`update-profile`]);
  }

  getBusinessHierarchy() {
    setTimeout(() => {
      let cmpId = localStorage.getItem('companyId') ? localStorage.getItem('companyId') : null;
      if (cmpId) {
        this.entityService.fetchEntity().subscribe((response: BaseResponse) => {
          this.BNBusinessList = response.response;
          this.BNBusinessList.sort((a,b) => a.company.localeCompare(b.company));
          if (cmpId) {
            this.entityService.fetchBusinessHierarchy(cmpId).subscribe((response: BaseResponse) => {
              this.BNModel = response.response;
              this.isRole = response.response.roleName;

            }, (err) => {
              // this.toaster.showError("Network Error");
            });
          }
        }, (err) => {
          // this.toaster.showError("Network Error");
        });
      }
    }, 1000)
  }

  getSetNavContext(navContext?) {
    if (navContext) {
      localStorage.setItem('navContext', JSON.stringify(navContext));
      this.getterSetter.setBusinessData(navContext);
    } else {
      navContext = JSON.parse(localStorage.getItem('navContext'));
      if (!navContext) {
        navContext = { entityName: '', isTaxPayer: false }
      }
    }
    return navContext;
  }

  changeBusiness(companyId) {
    if (companyId) {
      this.entityService.changeCompany(companyId).subscribe((response: BaseResponse) => {
        this.BNSelectedComs = [];
        if (response.status === 'SUCCESS') {
          if (response.response && response.response.length > 0 && response.response[0].companyid) {
            let companyId = response.response[0].companyid;
            let company = response.response[0].company;
            if(response.response[0].hasTenantChanged) {
              this.logout();
            }
            if(response.response[0].sapphireTenant) {
              this.GetSet.getSetSapphireTenent(response.response[0].sapphireTenant);
            }
            this.NavEntityContext = { entityName: '', isTaxPayer: false, entityType: 'Business' };
            this.getSetNavContext(this.NavEntityContext);
            this.subArrowShow = true;
            this.GetSet.getSetCompanyId(companyId);
            this.GetSet.getSetCompany(company);
            this.getBusinessHierarchy();
            if (response.response[0].products.length > 0) {
              this.GetSet.setProduct(response.response[0].products);
            }
            /**
             * Code for reloading route after action
             */
            this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
            this.router.navigateByUrl('dashboard')
              .then(() => {
                this.router.navigated = false;
                this.router.navigate([`dashboard`]);
              });
            /**
             * end
             */

            this.toaster.showSuccess(response.message);
          }
        } else {
          this.toaster.showInfo(response)
        }
      }, (err) => {
        // this.toaster.showError("Network Error!");
      });
    }
  }

  GotoUserDash() {
    this.NavEntityContext = { entityName: '', isTaxPayer: false, entityType: 'Business' };
    this.getSetNavContext(this.NavEntityContext);
    this.BNModel = {};
    this.subArrowShow = true;
    this.BNSelectedComs = [];
    this.getBusinessHierarchy();
  }

}
