// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'https://api.irisgst.com/irisgst',
  apiUrl: "https://dev.api.irisgst.com/irisgst",
  subscriptionKey: "d9fde4239a7cafb5481795757580e657",
  topazUrl: "topaz-dev.app.irisgst.com",
  onyxUrl: "onyx-dev.app.irisgst.com",
  lmsUrl: "dev.lms.irisgst.com",
  sapphireUrl: null,
  recaptcha: {
    siteKey: "6LcJbJEpAAAAAAnIDjBsSHF0XSEiCX1P3PNYV3Ye",
  }
  // apiUrl: 'https://api.irisgst.com/irisgst',
  // paymentURL: 'https://securegw-stage.paytm.in/order/process',
  // mockURL: 'https://276ba087-7722-4809-9f7c-77535216df1a.mock.pstmn.io',
  // firebase: {
  //   apiKey: 'AIzaSyCx5CwxCV_ten1_IliGVpWTTJQu7JnbmR8',
  //   authDomain: 'ezwb-81c83.firebaseapp.com',
  //   databaseURL: 'https://ezwb-81c83.firebaseio.com',
  //   projectId: 'ezwb-81c83',
  //   storageBucket: '',
  //   messagingSenderId: '589916789202',
  //   appId: '1:589916789202:web:1cd1468f7f0c77c3c7500b',
  //   measurementId: 'G-P9Y72C1WTD',
  //   vapidKey: 'BIHlEPdresplT4O-pNpvZtJnBaUbCN70LxanW28uAqHjU94OBYs76KjMMcPmbT_aAA2Tj-qqbHPLZeBO9ZJ44JI'
  // }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
