import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseResponse } from 'src/app/models/response';
import { BusinessEntityService } from 'src/app/shared/services/business-entity.service';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { AuthService } from '../../services/auth.service';
import { TokenService } from 'src/app/shared/services/token.service';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss']
})
export class VerifyOtpComponent implements OnInit {
  mfaForm: FormGroup
  mfaCode: any = null;
  isSubmitted: boolean = false;
  constructor(
    private toaster: ToasterService,
    private router: Router,
    private fBuild: FormBuilder,
    private businessService: BusinessEntityService,
    private authService: AuthService,
    private tokenService: TokenService,
    private getSet: GetterSetterService
  ) { }

  ngOnInit(): void {
    this.authService.setLoginIndicator(0);
    this.mfaForm = this.fBuild.group({
      code: ['', [Validators.required, Validators.pattern(new RegExp("^((\\+91-?)|0)?[0-9]{6}$"))]],
    });
  }

  verifyOtp() {
    this.isSubmitted = true;
    
    if (this.mfaForm.valid) {
      let data = {
        code : this.mfaForm.value.code,
        email: this.getSet.getSetUserId()
      }
      this.businessService.verifyOtp(data).subscribe((response: BaseResponse) => {
        if (response.status == 'SUCCESS') {
          this.authService.setLoginIndicator(2);
          this.toaster.showSuccess('Login Successful.');
          this.tokenService.setter(response.response);
          this.getSet.getSetUser(response.response.username);
          this.getSet.getSetSapphireTenent(response.response.sapphireTenant);
          this.getSet.getSetUserId(response.response.email);
          if (response.response.rootCompanyid != undefined) {
            this.getSet.getSetCompanyId(response.response.rootCompanyid);
            this.getSet.getSetCompany(response.response.rootCompanyName);
            if (response.response.products.length > 0) {
              this.getSet.setProduct(response.response.products);
            }
            this.router.navigate([`dashboard`]);
          } else {
            this.router.navigate([`add-business`]);
          }
        } else {
          this.toaster.showError(response.message);
        }
      });
    }
  }

}
