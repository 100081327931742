import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseResponse } from '../../../models/response';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ToasterService } from '../../../shared/services/toaster.service';
import { Router } from '@angular/router';
import { GetterSetterService } from '../../../shared/services/getter-setter.service';
import { AuthService } from '../../services/auth.service';
import { BusinessEntityService } from '../../../shared/services/business-entity.service';
import { TokenService } from 'src/app/shared/services/token.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {

  updateProfileForm: FormGroup;
  updatePassForm: FormGroup;
  isSubmitted: boolean = false;
  activeStep1: boolean = true;
  activeStep2: boolean = false;
  activeStep3: boolean = false;
  activeStep4: boolean = false;
  businessDetailsFilled: any;
  isSubmittedProfile: boolean = false;
  entityType: string;
  isTopazAccess: any;
  isSapphireAccess: any;
  isOnyxAccess: any;
  email: any;
  userEmail: any;
  notifiactionEmail: any;
  phoneNum: any;
  userName: any;
  middleName: any;
  lastName: any;
  aadhaarNum: any;
  qrData: any;
  otpForm: FormGroup;
  isOtpFormSubmitted: Boolean = false;
  mfaDetails: any = null;
  optOut: boolean = false;
  page:any = 0;
  size:any = 10;
  auditList:any = [];
  totalRecord:any = 0;
  firstParam:any = 2;
  constructor(
    private toaster: ToasterService,
    private router: Router,
    private fBuild: FormBuilder,
    private authService: AuthService,
    private businessService: BusinessEntityService,
    private getSet: GetterSetterService,
    private tokenService: TokenService,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    this.passwordFormInitialization();
    this.fetchMFADetails();
    this.email = localStorage.getItem('UserId');
    this.businessService.getUserProfileData().subscribe((response: BaseResponse) => {
      if (response.status == 'SUCCESS') {
        this.userEmail = response.response[0].email;
        this.notifiactionEmail = response.response[0].notificationEmail;
        this.phoneNum = response.response[0].phonenumer;
        this.userName = response.response[0].username;
        this.lastName = response.response[0].lastname;
        this.middleName = response.response[0].middlename;
        this.aadhaarNum = response.response[0].aadhaar;
        this.formInitialization();
      } else {
        this.toaster.showError(response.message);
      }
    });
  }

  passwordFormInitialization() {
    this.updatePassForm = this.fBuild.group({
      curentpassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16), Validators.pattern(new RegExp('(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=.\\[\\]*?])(?=\\S+$).{8,16}'))]],
      newpassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16), Validators.pattern(new RegExp('(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=.\\[\\]*?])(?=\\S+$).{8,16}'))]],
      confirmpassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16), Validators.pattern(new RegExp('(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=.\\[\\]*?])(?=\\S+$).{8,16}'))]],
    });
  }



  fetchMFADetails() {
    this.qrData = {};
    this.businessService.fetchMFADetails().subscribe((response: BaseResponse) => {
      if (response.status == "SUCCESS") {
        if(response.message == '2-Factor Authentication not enabled for this user' && response.response == null){
          this.mfaDetails = null;
          this.qrData = null;
        }else if (response.message == '2-Factor Authentication details found' && response.response.mfaVerified == 0 ) {
          this.qrData['qrCodeURL'] = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' 
            + response.response.QRCode);
        }else if (response.message == '2-Factor Authentication details found' && response.response.mfaVerified == 1){
          this.mfaDetails = response.response;
        }
      } else {
        this.toaster.showError(response.message);
      }
    });
  }

  OpenForm(step: string) {
    if(step == 'step4'){
      this.fetchAuditLogDetail();
    }

  }

  fetchAuditLogDetail(){
    this.businessService.fetchAuditLogs(this.page,this.size).subscribe((response: BaseResponse) => {
      if (response.status == "SUCCESS") {
        this.auditList = response.response.content;
        this.totalRecord = response.response.totalElements;
      } else {
        this.toaster.showError(response.message);
      }
    });
  }

  onPageChange(event){
    this.page = event.page;
    this.size = event.rows;
    this.fetchAuditLogDetail();
  }


  formInitialization() {
    this.updateProfileForm = this.fBuild.group({
      username: [this.userName, [Validators.required]],
      middlename: [this.middleName, []],
      lastname: [this.lastName, [Validators.required]],
      email: [this.userEmail, [Validators.required, Validators.email]],
      notificationEmail: [this.notifiactionEmail, [Validators.required, Validators.email]],
      phonenumer: [this.phoneNum, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"), Validators.minLength(10), Validators.maxLength(10)]],
      aadhaar: [this.aadhaarNum, [Validators.minLength(12), Validators.maxLength(12), Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]]
    });

    this.otpForm = this.fBuild.group({
      code: ['', [Validators.required, Validators.pattern(new RegExp("^((\\+91-?)|0)?[0-9]{6}$"))]],
    });

  }

  get f() { return this.updatePassForm.controls; }

  get otpF() { return this.updatePassForm.controls; }

  updatePassword() {
    this.isSubmitted = true;
    if (this.updatePassForm.valid && this.email) {
      if (this.updatePassForm.value.newpassword == this.updatePassForm.value.confirmpassword) {
        this.updatePassForm.value.changePasswordFromLogin = true;
        this.updatePassForm.value.email = this.email;
        this.authService.changePassowrd(this.updatePassForm.value).subscribe((response: BaseResponse) => {
          if (response.status == 'SUCCESS') {
            this.toaster.showSuccess('Password changed successfylly.');
            if (this.tokenService.destroy()) {
              this.authService.setLoginIndicator(0);
              this.router.navigate(["login"]);
            }

          } else {
            this.toaster.showError(response.message);
          }
        });
      } else {
        this.toaster.showError("New Password and confirmation password do not match");
      }
    }
  }

  updateProfile() {
    this.isSubmittedProfile = true;
    if (this.updateProfileForm.valid) {
      this.businessService.updateUserProfileData(this.updateProfileForm.value).subscribe((response: BaseResponse) => {
        if (response.status == 'SUCCESS') {
          this.toaster.showSuccess('Profile updated successfully.');
        } else {
          this.toaster.showError(response.message);
        }
      });
    }
  }

  toDashboard() {
    this.router.navigate(['dashboard']);
  }

  fetchQRCode() {
    this.businessService.fetchQRData().subscribe((response: BaseResponse) => {
      if (response.status == 'SUCCESS' && response.message == "2-Factor Authentication enabled succesfully") {
        this.qrData = response.response;
        if(response.response && response.response.qrCodeURL){
          this.qrData['qrCodeURL'] = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' 
            + response.response.qrCodeURL);
        }
      } else {
        this.toaster.showError(response.message);
      }
    });
  }

  logout() {
    if (this.tokenService.destroy()) {
      this.authService.setLoginIndicator(0);
      this.router.navigate(['login']);
    } else {
      this.toaster.showError('Something Went wrong !');
    }
  }

  verifyOtp() {
    this.isSubmittedProfile = true;
    if (this.otpForm.valid) {
      let data = {
        code: this.otpForm.value.code,
        email: this.getSet.getSetUserId()
      }
      this.businessService.verifyOtp(data).subscribe((response: BaseResponse) => {
        if (response.status == 'SUCCESS') {
          this.toaster.showSuccess('Multi Factor Authentication Enabled successfully.');
          this.logout();
        } else {
          this.toaster.showError(response.message);
        }
      });
    }
  }

  onOptOutChange() {
    this.businessService.optout().subscribe((response: BaseResponse) => {
      if (response.status == 'SUCCESS' && response.message == "Deactivated 2-Factor Authentication") {
        this.toaster.showSuccess('Deactivated Multi-Factor Authentication');
        this.router.navigate(['dashboard']);
        this.getSet.setMFACode(false);
      } else {
        this.toaster.showError(response.message);
      }
    });
  }

}
