<div class="container-scroller">
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <div class="content-wrapper d-flex align-items-stretch auth auth-img-bg">
      <div class="row flex-grow main-panel">
        <div class="content-wrapper d-flex  align-items-center justify-content-center auth px-0">
          <div class="row w-100 mx-0">
            <div class="col-lg-4 mx-auto">
              <div class="auth-form-transparent  text-left py-5 px-4 px-sm-5 border">
                <div class="brand-logo">
                  <img src="assets/images/IRISGST-logo.png" alt="logo">
                </div>
                <form [formGroup]="domainForm" class="pt-3">
                  <h3 class="clrWhite">Sign in to SAML</h3>
                  <div class="form-group">
                    <label for="exampleInputEmail">Select Domain</label>
                    <div class="input-group">
                      <select class="form-control" (change)="changeDomain($event)" formControlName="domainName">
                        <!-- <option>-- Select Domaint --</option> -->
                        <ng-container *ngFor="let obj of domainList">
                          <option [value]="obj.key">{{obj.value}}</option>
                        </ng-container>
                      </select>
                    </div>
                    <div *ngIf="domainName?.invalid && domainForm.get('domainName').touched">
                      <span class="text-danger"
                        *ngIf="domainForm.get('domainName').touched && domainForm.get('domainName').errors.required">Please
                        Select Domain
                      </span>
                    </div>
                  </div>
                  <div class="my-3">
                    <button [disabled]="!domainForm.valid" class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" (click)="signInSAML()">Next</button>
                  </div>
                  <div class="my-2 d-flex justify-content-between align-items-center">
                    <a [routerLink]="'/login'" class="auth-link clrWhite"> Sign In using Credentials</a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
