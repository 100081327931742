import { Component, OnInit } from '@angular/core';
import { TokenService } from '../../../shared/services/token.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ToasterService } from '../../../shared/services/toaster.service';

@Component({
  selector: 'app-session-clear',
  templateUrl: './session-clear.component.html',
  styleUrls: ['./session-clear.component.scss']
})
export class SessionClearComponent implements OnInit {

  constructor(
    private tokenService: TokenService,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.logout();
  }

  logout() {
    if (this.tokenService.destroy()) {
      this.authService.setLoginIndicator(0);
      this.router.navigate(['login']);
    }
  }

}
