<div class="main-panel" *ngIf="!subscriptionFlag">
  <div class="content-wrapper">
    <div class="row">
      <div class="col-12 mt-3">
        <div class="tab-content pl0 pr0">
          <div class="tab-pane fade show active" id="ewb" role="tabpanel" aria-labelledby="ewb-tab">
            <div class="row">
              <div class="col-xl-12 grid-margin stretch-card">
                <div class="card">
                  <div class="row">
                    <div class="col-md-12 stretch-card">
                      <div class="card-body">
                        <form class="forms-sample" [formGroup]="verificationForm" autocomplete="off">
                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group row pb-1">
                                <div class="col-sm-12">
                                  <label class="col-sm-3 col-form-label">Navigation Key</label>
                                  <input type="text" class="form-control" id="Input-field"
                                    placeholder="Enter the valid key" formControlName="password"
                                    [ngClass]="{ 'errorBorder': isSubmitted && vf.password.errors }">
                                  <div *ngIf="isSubmitted && vf.password.errors">
                                    <span class="text-danger" *ngIf="vf.password.errors.required"> Key
                                      Required
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4" style="margin-top: 45px;">
                              <button type="button" class="btn btn-outline-primary mr-1"
                                (click)="verfiyPassword(true)">Proceed</button>
                              <button type="button" class="btn btn-outline-primary mr-1" (click)="navigateToDashboard()">Cancel</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="main-panel" *ngIf="subscriptionFlag">
  <div class="content-wrapper">
    <div class="row">
      <div class="col-12 mt-3">
        <div class="row">
          <div class="col-md-8">
            <ul class="nav nav-tabs iris-gst-display-flex" role="tablist">
              <li class="nav-item show active">
                <a class="nav-link " id="ewb-tab" data-toggle="tab" href="#ewb" role="tab" aria-controls="ewb"
                  aria-selected="true" (click)="clearData()">Subscription</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="tab-content pl0 pr0">
          <div class="tab-pane fade show active" id="ewb" role="tabpanel" aria-labelledby="ewb-tab">
            <div class="row">
              <div class="col-xl-12 grid-margin ht-auto stretch-card">
                <div class="card">
                  <div class="row">
                    <div class="col-md-12 ht-auto stretch-card">
                      <div class="card-body">
                        <form class="forms-sample" [formGroup]="subcribeForm" autocomplete="off">
                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group row pb-1">
                                <div class="col-sm-12">
                                  <label class="col-sm-3 col-form-label">PAN Number</label>
                                  <input type="text" class="form-control" id="Input-field"
                                    placeholder="Enter PAN number" formControlName="pan"
                                    [ngClass]="{ 'errorBorder': isSubmitted && ef.pan.errors }">
                                  <div *ngIf="isSubmitted && ef.pan.errors">
                                    <span class="text-danger" *ngIf="ef.pan.errors.required"> PAN
                                      Required
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                        <div class="my-4 row align-item-center">
                          <div class="col-4">
                            <label class="custom-check">Sapphire
                              <input type="checkbox" [(ngModel)]="sapphire" (change)="setProduct()">
                              <span class="checkmark"></span>
                            </label>
                          </div>
                          <div *ngIf="sapphire" class="col-4">
                            <select class="form-control" [(ngModel)]="sapphireTenantDoamin">
                              <option [value]="0">-- Select Tenant --</option>
                              <ng-container *ngFor="let obj of tenantList">
                                <option [value]="obj.key">{{obj.value}}</option>
                              </ng-container>
                            </select>
                          </div>
                        </div>
                        <div class="my-4 row align-item-center">
                          <div class="col-2">
                            <label class="custom-check">Onyx
                              <input type="checkbox" [(ngModel)]="onyx" (change)="setProduct()">
                              <span class="checkmark"></span>
                            </label>
                          </div>
                          <div *ngIf="onyx" class="col-2">
                            <label  class="custom-check">Onyx Lite
                              <input type="checkbox" [(ngModel)]="onyxLite" (change)="setProduct()">
                              <span class="checkmark"></span>
                            </label>
                          </div>
                          <div *ngIf="onyxLite" class="col-4 ">
                            <form class="forms-sample" [formGroup]="emailForm" autocomplete="off">
                              <input type="email" placeholder="Enter email" class="form-control" formControlName="email" [ngClass]="{ 'errorBorder': isSubmitted && getEF.email.errors }">
                              <div *ngIf="isSubmitted ">
                                <span class="text-danger" *ngIf="!emailForm.get('email').dirty && emailForm.get('email').errors.required">Email Required</span>
                                <span class="text-danger" *ngIf="emailForm.get('email').dirty && emailForm.get('email').errors">Invalid Email</span>
                            </div>
                            </form>
                          </div>
                        </div>
                        <div class="mt-3">
                          <label class="custom-check">Topaz
                            <input type="checkbox" [(ngModel)]="topaz" (change)="setProduct()">
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="row">
                          <div class="col-md-4 pt-xl-5">
                            <button class="btn btn-outline-primary mr-1" id="clickSearch"
                              (click)="search()">Submit</button>
                            <button class="btn btn-outline-primary mr-1" (click)="clearData()">Clear</button>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
