import { Injectable } from '@angular/core';
import { GetterSetterService } from './getter-setter.service';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  constructor(
    private GetSet: GetterSetterService
  ) {

  }


  getter() {
    return localStorage.getItem('jwtToken');
  }

  setter(data) {
    localStorage.setItem('jwtToken', data.token);
    localStorage.setItem('logintoken', data.token);
    // localStorage.setItem('userId', data.email);
    this.GetSet.getSetUserId(data.email)
    this.GetSet.getSetCompanyId(data.companyid)
    return 1;
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);
    if (decoded['exp'] === undefined) { return null; }
    const date = new Date(0);
    date.setUTCSeconds(decoded['exp']);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) { token = this.getter(); }
    if (!token) { return true; }

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) { return false; }
    return !(date.valueOf() > new Date().valueOf());
  }

  destroy() {
    localStorage.clear();
    return true;
  }
}
