import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ToasterService } from '../../../shared/services/toaster.service';
import { BaseResponse } from '../../../models/response';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  isSubmitted: boolean = false;
  email: string;
  constructor(
    private fbuild: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private toaster: ToasterService,
  ) { }

  ngOnInit() {
    this.authService.getLoginEmail().subscribe( (data) => {
      this.email = data;
    });
    this.formInitialization();
  }
  formInitialization() {
    this.changePasswordForm = this.fbuild.group({
      curentpassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16), Validators.pattern(new RegExp('(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=.\\[\\]*?])(?=\\S+$).{8,16}'))]],
      newpassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16), Validators.pattern(new RegExp('(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=.\\[\\]*?])(?=\\S+$).{8,16}'))]],
      confirmpassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16), Validators.pattern(new RegExp('(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=.\\[\\]*?])(?=\\S+$).{8,16}'))]],
    });
  }
  get f() { return this.changePasswordForm.controls; }

  changePassword() {
    this.isSubmitted = true;
    if (this.changePasswordForm.valid && this.email) {
      if (this.changePasswordForm.value.newpassword == this.changePasswordForm.value.confirmpassword) {
        this.changePasswordForm.value.changePasswordFromLogin = true;
        this.changePasswordForm.value.email = this.email;
        this.authService.changePassowrd(this.changePasswordForm.value).subscribe((response: BaseResponse) => {
          if (response.status == 'SUCCESS') {
            this.toaster.showSuccess('Password changed successfylly.');
            this.router.navigate(['login']);
          } else {
            this.toaster.showError(response.message);
          }
          this.changePasswordForm.value.changePasswordFromLogin = false;
        });
      } else {
        this.toaster.showError("New Password and confirmation password do not match");
      }
    } 
  }

}
