<div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-stretch auth auth-img-bg">
            <div class="row flex-grow main-panel">
                <div class="content-wrapper d-flex  align-items-center justify-content-center auth px-0">
                    <div class="row w-100 mx-0">
                        <div class="col-lg-4 mx-auto">
                    <div class="auth-form-transparent text-left py-5 px-4 px-sm-5 border">
                        <div class="brand-logo">
                            <img src="assets/images/IRISGST-logo.png" alt="logo">
                        </div>
                        <form [formGroup]="resetForm" class="pt-3">
                            <div class="form-group">
                                <label for="exampleInputPassword">Reset Password</label>
                                <div class="input-group">
                                    <input type="password" class="form-control form-control-lg border-left-0" formControlName="newpassword" 
                                        placeholder="New Password">
                                </div>
                                <div>
                                    <span class="text-danger" *ngIf="!resetForm.get('newpassword').dirty && resetForm.get('newpassword').errors.required">Password
                                        Required
                                    </span>
                                    <span class="text-danger" *ngIf="resetForm.get('newpassword').dirty && resetForm.get('newpassword').errors">Invalid
                                        Password
                                    </span>
                                </div>
                            </div>
                            <div class="mb-2 d-flex">
                                <button type="button" class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" (click)="resetPassword()">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                    </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>
