<div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-stretch auth auth-img-bg">
            <div class="row flex-grow  main-panel">
                <div class="content-wrapper d-flex  align-items-center justify-content-center auth px-0">
                    <div class="row w-100 mx-0">
                        <div class="col-lg-4 mx-auto">
                    <div class="auth-form-transparent text-left py-5 px-4 px-sm-5 border">
                        <div class="brand-logo">
                            <img src="assets/images/IRISGST-logo.png" alt="logo">
                        </div>
                        <h4 class="clrWhite">Change Password</h4>
                        <form [formGroup]="changePasswordForm" class="pt-3" autocomplete="off">
                            
                            <div class="form-group">
                                <input type="password" class="form-control form-control-lg "
                                    formControlName="curentpassword" placeholder="Current Password" [ngClass]="{ 'errorBorder': isSubmitted && f.curentpassword.errors }">
                                <div *ngIf="isSubmitted && f.curentpassword.errors">
                                    <span class="text-danger"
                                    *ngIf="f.curentpassword.errors.required">Current Password
                                        Required
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="!f.curentpassword.errors.required && f.curentpassword.errors">Invalid Current
                                        Password
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="password" class="form-control form-control-lg "
                                    formControlName="newpassword" placeholder="New Password" [ngClass]="{ 'errorBorder': isSubmitted && f.newpassword.errors }">
                                <div *ngIf="isSubmitted && f.newpassword.errors">
                                    <span class="text-danger"
                                    *ngIf="f.newpassword.errors.required">New Password
                                        Required
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="!f.newpassword.errors.required && f.newpassword.errors">Invalid New
                                        Password
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="password" class="form-control form-control-lg "
                                    formControlName="confirmpassword" placeholder="Confirm Password" [ngClass]="{ 'errorBorder': isSubmitted && f.confirmpassword.errors }">
                                <div *ngIf="isSubmitted && f.confirmpassword.errors">
                                    <span class="text-danger"
                                    *ngIf="f.confirmpassword.errors.required">Confirm Password
                                        Required
                                    </span>
                                </div>
                            </div>
                            <div class="mb-2 d-flex">
                                <button type="button" class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                                    (click)="changePassword()">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                    </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>
